var l = (e, r, t) => new Promise((n, i) => {
  var d = o => {
      try {
        c(t.next(o));
      } catch (a) {
        i(a);
      }
    },
    m = o => {
      try {
        c(t.throw(o));
      } catch (a) {
        i(a);
      }
    },
    c = o => o.done ? n(o.value) : Promise.resolve(o.value).then(d, m);
  c((t = t.apply(e, r)).next());
});
function f(e) {
  return document.querySelector(`script[src="${e}"]`);
}
function S(e) {
  let r = document.head || document.body;
  if (!r) throw new Error("Square.js requires a <body> or <head> element.");
  let t = document.createElement("script");
  return t.src = e, r.appendChild(t), t;
}
var s = null;
function p(e) {
  return s !== null || (s = new Promise((r, t) => {
    if (typeof window == "undefined") {
      r(null);
      return;
    }
    if (window.Square) {
      r(window.Square);
      return;
    }
    try {
      let n = f(e);
      n || (n = S(e)), n.addEventListener("load", () => {
        window.Square ? r(window.Square) : t(new Error("Square.js failed to load properly."));
      }), n.addEventListener("error", () => {
        t(new Error("Error occurred while loading Square.js"));
      });
    } catch (n) {
      t(n);
    }
  })), s;
}
var w = "v1",
  u = class extends Error {
    constructor(r = "The Payment 'applicationId' option is not in the correct format.") {
      super(r);
      this.name = "InvalidApplicationIdError", Object.setPrototypeOf(this, u.prototype);
    }
  };
function q(e) {
  let r = "";
  if (e.startsWith("sq0idp-") && (r = "https://web.squarecdn.com/"), e.startsWith("sandbox-sq0idb-") && (r = "https://sandbox.web.squarecdn.com/"), r.length === 0) throw new u();
  return r += `${w}/square.js`, r;
}
function E(e, r, t) {
  return l(this, null, function* () {
    let n = (t == null ? void 0 : t.scriptSrc) !== void 0 ? t.scriptSrc : q(e),
      i = yield p(n);
    return i === null ? null : i.payments(e, r);
  });
}
export { E as payments };